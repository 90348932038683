.carrier-content-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .smaller-landing.carrier::before {
    background-color: rgba(0, 0, 0, 0.06);
  }
  
  .carrier-content-section .content-section {
    flex: 1;
  }
  
  .form-section {
    flex: 1;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .carrier-content-section form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-bottom: 5px;
  }
  
  .carrier-content-section input[type="text"],
  .carrier-content-section input[type="email"],
  .carrier-content-section input[type="tel"],
  .carrier-content-section textarea {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .carrier-content-section textarea {
    height: 100px;
    max-height: 100px;
    min-height: 100px;
  }
  
  .carrier-content-section button {
    background-color: var(--primary);
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .carrier-content-section button:hover {
    background-color: #001a4a;
  }

  @media (max-width: 820px) {
    .carrier-content-section {
      flex-direction: column;
      gap: 50px;
      align-items: center;
    }
    .carrier-content-section label {
      text-align: center;
    }
    input::placeholder {
      text-align: center;
    }
  }