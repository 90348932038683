.homepage-landing {
    display: grid;
    place-items: center;
    justify-content: start;
    min-height: 100dvh;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    margin-left: 0vw;
    background-position: center;
    position: relative; /* Potřebné pro absolutní pozicování pseudo-elementu */
    overflow: hidden; /* Zabráníme přesahu obsahu */
}

.homepage-landing::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 10%);
    z-index: 1;
    transition: opacity 0.5s ease, filter 0.5s ease;
    pointer-events: none;
}

.homepage-landing.loading::before {
    filter: blur(5px); /* Rozmazání pozadí při načítání */
    opacity: 1; /* Zajištění, že overlay je viditelný */
}

.homepage-landing.loaded::before {
    filter: none; /* Odstranění rozmazání po načtení obrázku */
    opacity: 1; /* Udržuje overlay viditelný, můžeš upravit na 0 pokud chceš zmizení */
}

.homepage-landing > * {
    position: relative;
    z-index: 2; /* Obsah zůstane nad overlayem */
}

.homepage-landing-content h1 {
    font-size: 64px;
    margin: 20px 0;
}

.homepage-landing-content {
    color: #f2f2f2;
    max-width: 90vw;
    margin: auto;
    margin-left: 17.5%;
}

.homepage-landing-content h3 {
    font-weight: normal;
    max-width: 50vw;
    text-align: justify;
    font-weight: 700;
    word-spacing: -1px;
}

.how-to-content {
    max-width: 40vw;
    position: sticky;
    top: 30vh; /* Výška od vrcholu stránky, kdy se sekce začne držet na místě */
}

.how-to {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    align-items: flex-start; /* Zajistí, že komponenty začínají od horního okraje */
}

@media (max-height: 800px) {
    .homepage-landing-content h1 {
        font-size: 48px;
    }
    .homepage-landing-content h3 {
        max-width: 66vw;

    }
}

@media (max-width: 1300px) {
    .how-to {
        flex-direction: column;
        align-items: center;
    }
    .how-to-content {
        text-align: center;
        max-width: unset;
        position: unset;
        top: unset;
    }
    .homepage-landing-content {
        margin-left: 7.5%;
    }
} 

@media (max-width: 768px) {
    .homepage-landing-content h1 {
        font-size: 48px;
        text-align: center;
    }
    .homepage-landing-content h3 {
        max-width: unset;
        text-align: center;
    }
    .how-to-content {
        max-width: unset;
    }
}

@media (max-width: 450px) {
    .homepage-landing-content {
        margin: auto;
        max-width: 80vw;
    }
    .homepage-landing-content h1 {
        font-size: 2rem;
    }
    .homepage-landing-content h3 {
        font-size: 1rem;
    }
    .homepage-landing {
        justify-content: unset;
    }
}