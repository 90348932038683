.contact-us-container {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 40vh;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100vw;
    left: calc(-15vw - 100px);
    text-align: left;
    margin: 0 100px;
    overflow: hidden; /* Zabráníme přesahu obsahu */
    padding: 100px 0;
}

.contact-us-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* Černá barva s průhledností */
    z-index: 1;
    transition: opacity 0.5s ease, filter 0.5s ease;
    pointer-events: none; /* Zabrání interakci s overlay */
}

.contact-us-container.loading::before {
    filter: blur(5px); /* Rozmazání pozadí při načítání */
    opacity: 1; /* Zajištění, že overlay je viditelný */
}

.contact-us-container.loaded::before {
    filter: none; /* Odstranění rozmazání po načtení obrázku */
    opacity: 1; /* Overlay zůstává viditelný */
}

.contact-us-container * {
    position: relative;
    z-index: 2; /* Zajistí, že text bude nad tmavou vrstvou */
    max-width: 80%;
    margin: 20px auto;
    text-align: center;
}

.contact-us-container_links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.contact-us-container_links a {
    color: white;
    text-decoration: none;
}

.contact-us-container_links a:first-child {
    background-color: var(--primary);
    border: 3px var(--primary) solid;
    padding: 10px 20px;
}

.contact-us-container_links a:first-child:hover {
    background-color: var(--accent);
    border: 3px var(--accent) solid;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
}

.contact-us-container_links a:last-child {
    border: 3px var(--primary) solid;
    padding: 10px 20px;
}

.contact-us-container_links a:last-child:hover {
    background-color: var(--primary);
    padding: 10px 20px;
    transition: background-color 0.3s ease;
}

@media (max-width: 1200px) {
    .contact-us-container {
        padding: 100px 0;
    }
}

@media (max-width: 768px) {
    .contact-us-container {
        left: -5vw;
        width: 100vw;
        margin: unset;
    }
}

@media (max-width: 420px) {
    .contact-us-container {
        padding: 100px 0;
    }
    .contact-us-container_links {
        flex-direction: column;
        gap: 20px;
    }

    .contact-us-container_links * {
        margin: 0 auto;
    }
}