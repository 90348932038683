.notification {
    position: fixed !important;
    top: 100px; /* Nastavení vzdálenosti odshora */
    right: 50px; /* Nastavení vzdálenosti zprava */
    padding: 15px 20px;
    border-radius: 5px;
    background-color: #333;
    color: #fff;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
    z-index: 1000;
}

.notification.show {
    opacity: 1;
    transform: translateY(0);
}

.notification.success {
    background-color: #28a745;
}

.notification.error {
    background-color: #dc3545;
}