.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  background-color: #FFF;
  z-index: 1000;
  transition: height 0.3s ease, padding 0.3s ease;
  height: 70px; 
}

.navbar.scrolling {
  height: 50px; 
}

.navbar-links a.active {
  font-weight: bold; 
}

.navbar-inner {
  width: 100%;
  max-width: 70vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo img {
  width: 200px;
  transition: width 0.3s ease;
}

.navbar.scrolling .navbar-logo img {
  width: 150px; /* Zmenšení loga při scrollování */
}

.navbar-links {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navbar-links a {
  text-decoration: none;
  color: #000;
}

.navbar-cta button {
  padding: 10px 20px;
  background-color: var(--primary);
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.navbar-cta button:hover {
  background-color: var(--accent);
  transition: background-color 0.5s ease;
}

.burger-menu {
  display: none; /* Zobrazíme burger menu na menších obrazovkách */
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  z-index: 1100;
  transition: transform 0.3s ease; /* Animace pro přechod na křížek */
}

.burger-menu .line {
  width: 25px;
  height: 3px;
  background-color: #000;
  transition: all 0.3s ease;
}

.burger-menu.open .line:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px); /* Animace první čáry do křížku */
}

.burger-menu.open .line:nth-child(2) {
  opacity: 0; /* Skryjeme druhou čáru */
}

.burger-menu.open .line:nth-child(3) {
  transform: rotate(-45deg) translate(6px, -6px); /* Animace třetí čáry do křížku */
}

/* Mobilní menu overlay */
.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 25vh; /* Menu bude zabírat čtvrtinu obrazovky */
  background-color: #FFF; /* Bílé pozadí */
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Odkazy vycentrované horizontálně */
  text-align: right; /* Zarovnání textu doprava */
  z-index: 1050;
  transition: opacity 0.3s ease, height 0.3s ease;
  opacity: 0;
  pointer-events: none;
  padding-right: 10px; /* Padding na pravé straně */
  padding-left: 10px; /* Padding na levé straně */
  border-bottom: 1px solid #ddd; /* Čára dole */
}

.menu-overlay.open {
  display: flex;
  opacity: 1;
  pointer-events: all;
  height: 35%;
}

.menu-logo {
  position: absolute;
  top: 20px;
  left: 7vw;
}

.menu-logo img {
  width: 200px; /* Velikost loga v menu */
}

.close-menu {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  color: black;
  cursor: pointer;
}

.menu-content {
  display: flex;
  flex-direction: column;
  align-items: center; /* Odkazy vycentrované vertikálně */
  gap: 20px;
}

.menu-content a {
  color: #000;
  font-size: 18px;
  text-decoration: none;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #ddd; /* Čáry mezi odkazy */
}

.menu-content a.active {
  font-weight: bold;
}

.mobile-cta a.active {
  font-weight: normal;
}

.menu-content a:last-child {
  border-bottom: none; /* Poslední odkaz nemá čáru dole */
}

/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal box */
.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  position: relative;
}

/* Close button */
.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

/* Form styling */
.modal-form .form-group {
  margin-bottom: 15px;
}

.modal-form .form-group label {
  display: block;
  margin-bottom: 5px;
}

.modal-form .form-group input[type="text"],
.modal-form .form-group input[type="email"],
.modal-form .form-group input[type="tel"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-form .form-group-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.modal-form .form-group-checkbox label {
  margin-left: 5px;
}

.modal-form button[type="submit"] {
  padding: 10px 20px;
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-open {
  overflow: hidden;
}

.modal-form button[type="submit"]:hover {
  background-color: var(--accent);
}

@media (max-width: 1300px) {
  .navbar-inner {
    max-width: 90vw;
  }
}

@media (max-width: 1000px) {
  .navbar-links {
    display: none; /* Skryjeme odkazy na menších obrazovkách */
  }

  .burger-menu {
    display: flex; /* Zobrazíme burger menu na menších obrazovkách */
  }
}

@media (max-width: 768px) {
  .navbar-inner {
    padding: 10px 20px;
    overflow: hidden;
  }
  .navbar {
    padding: unset;
  }
  .navbar-logo img {
    max-width: 150px;
  }
}

@media (max-width: 650px) {
  .menu-overlay.open {
    height: 45%;
  }
}

@media (max-width: 450px) {
  .menu-content {
    margin-top: 70px;
    gap: unset;
  }
  .menu-overlay.open {
    height: 60%;
  }
}

@media (min-width: 1301px) {
  .menu-overlay {
    display: none !important; /* Skryjeme menu overlay na větších obrazovkách */
  }
}