.faq-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .faq-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .faq-container p {
    text-align: center;
    margin-bottom: 40px;
    color: #555;
  }
  
  .faq-item {
    cursor: pointer;
    margin: 10px 0;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .faq-question h3 {
    margin: 0;
    font-size: 1.2rem;
  }
  
  .faq-item p {
    margin: 10px 0 20px 0;
    padding-left: 20px;
    color: #333;
    text-align: left;
  }
  
  .faq-item hr {
    border: 0;
    height: 1px;
    background: #ddd;
    margin: 10px 0;
  }
  
  .faq-question:hover {
    background-color: #f1f1f1;
  }
  
  .contact-button {
    display: block;
    margin: 30px auto;
    padding: 10px 20px;
    background-color: var(--accent);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .contact-button:hover {
    background-color: #0000cc;
  }