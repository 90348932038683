/* Základní stylování footera */
.footer {
    padding: 40px 20px;
    border-top: 1px solid #000;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
}

.footer-logo img {
    max-width: 150px;
}

.footer-info h3,
.footer-subscribe h3 {
    font-size: 18px;
    font-weight: bold;
}

.footer-info ul {
    list-style: none;
    padding: 0;
}

.footer-info ul li {
    margin-bottom: 10px;
}

.footer-info ul li a {
    text-decoration: none;
    color: #000;
    font-size: 16px;
}

.footer-subscribe p {
    margin: 10px 0;
    font-size: 14px;
    color: #000;
}

.subscribe-form {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.subscribe-form input {
    padding: 10px;
    border: 1px solid #000;
    flex-grow: 1;
    font-size: 14px;
}

.subscribe-form input[type="submit"] {
    padding: 10px 20px;
    border: 1px solid #000;
    color: #000;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.subscribe-form button:hover {
    background-color: #e0e0e0;
}

/* Spodní část footera */
.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    border-top: 1px solid #000;
    margin-top: 20px;
}

.footer-bottom p {
    font-size: 14px;
    color: #000;
}

.social-icons a {
    color: #000;
    font-size: 18px;
    margin-left: 20px;
    text-decoration: none;
    transition: color 0.3s ease;
}

.social-icons a:hover {
    color: #555;
}

/* Responzivní stylování */
@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer-bottom {
        flex-direction: column;
        gap: 20px;
        text-align: center;
    }
}