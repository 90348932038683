.contact-container {
    padding: 40px 20px;
    margin: 0 auto;
  }
  
  .smaller-landing.contact {
    background-position: center 0; /* Posune pozadí o 20% nahoru */
  }

  .smaller-landing.contact::before {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .direct-contact {
    text-align: center;
    margin-bottom: 50px;
  }
  
  .direct-contact h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .direct-contact p {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  
  .contact-info {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    gap: 100px;
  }
  
  .contact-item {
    text-align: center;
    max-width: 300px;
    width: 300px;
  }
  
  .contact-item h4 {
    font-size: 1.2rem;
    margin: 10px 0;
  }
  
  .contact-item a,
  .contact-item address {
    font-size: 1rem;
    color: black;
    text-decoration: none;
  }
  
  .contact-item a:hover {
    text-decoration: underline;
  }
  
  /* Formulářová sekce */
  .contact-form-section {
    text-align: center;
    margin-bottom: 50px;
  }
  
  .contact-form-section h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    margin: 10px 0;
  }
  
  .contact-form-section p {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .form-row {
    display: flex;
    gap: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .form-group label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }

  .fakturacni {
    display: flex;
    justify-content: center;
    margin-top: 14px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .contact-form button {
    padding: 10px 20px;
    background-color: var(--primary);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    text-align: center;
  }
  
  .contact-form button:hover {
    background-color: var(--accent);
  }
  
  .form-group-checkbox {
    display: flex;
    align-items: center;
  }
  
  .form-group-checkbox label {
    margin-left: 10px;
  }
  
  @media (max-width: 768px) {
    .contact-info {
      flex-direction: column;
      align-items: center;
    }

    .contact-form {
      max-width: 75vw;
    }
  
    .form-row {
      flex-direction: column;
    }
  }