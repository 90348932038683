/* src/pages/NotFound.css */
.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .not-found-text h2 {
    font-size: 96px;
    margin: 0;
    color: var(--accent);

  }
  
  .not-found-text p {
    font-size: 24px;
    color: #333;
  }
  
  .back-home {
    margin-top: 20px;
    padding: 10px 20px;
    color: #fff;
    background-color: var(--accent);
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .back-home:hover {
    background-color: #0056b3;
  }
  
  .not-found-animation {
    margin-top: 50px;
    display: flex;
    gap: 20px;
  }
  
  .circle {
    width: 20px;
    height: 20px;
    background-color: #ff6b6b;
    border-radius: 50%;
    animation: bounce 1s infinite alternate;
  }
  
  .circle:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .circle:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes bounce {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-20px);
    }
  }