.partners-component .partner-container {
    display: flex; 
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 800px;
    margin: auto;
    -webkit-box-shadow: 0px 0px 52px 5px rgba(186,186,186,0.5);
    -moz-box-shadow: 0px 0px 52px 5px rgba(186,186,186,0.5);
    box-shadow: 0px 0px 52px 5px rgba(186,186,186,0.5);
}

.partner-container img {
    height: 100%;
    max-width: 12rem;
    width: 100%;
    object-fit: cover;
}

.partner-info {
    display: flex;
    padding: 2rem;
    align-items: center;
    gap: 2rem;
}

.partner-info a {
    color: black;
    text-decoration: none;
    border: 1px solid black;
    padding: 10px 20px;
    transition: 0.2s;
}

.partner-info a:hover {
    background-color: rgb(238, 238, 238);
    transition: 0.2s;
}

.partner-desc h4 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
}

.partners-component .partners-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.partners-component .partner-desc {
    /* text-align: justify;
    word-spacing: -2px; */
    text-align: left;
}

@media (max-width: 748px) {
    .partners-component .partner-container, .partner-info {
        flex-direction: column;
    }
    .partner-info {
        padding: 0 2rem 1rem;
        align-items: center;
    }
    .partners-component .partners-container {
        text-align: left;
    }
    .partner-container img {
        max-width: 15rem;
    }
    .partner-desc h4, .partner-desc p {
        text-align: center;
    } 
}