:root {
  --primary: #135C67;
  --accent: #2e92a2;
}

section {
  margin: 7rem auto;
  max-width: 70vw;
}

body {
  overflow-x: hidden;
}

@media (max-width: 768px) {
  section {
    max-width: 90vw;
  }
}