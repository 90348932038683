.newsletter-container {
    background-position: center calc(100% - 10%); /* Posunutí obrázku o 20px nahoru od spodního okraje */
    background-repeat: no-repeat;
    background-size: cover;
    height: 45vh;
    color: white;
    display: flex;
    flex-direction: row;
    gap: 100px;
    align-items: center;
    position: relative;
    width: 100vw;
    left: calc(-15vw - 100px);
    text-align: left;
    margin: 0 100px;
    overflow: hidden; /* Zabráníme přesahu obsahu */
    justify-content: center;
}

.newsletter-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: opacity 0.5s ease, filter 0.5s ease; /* Přidán přechod pro opacity a filter */
    pointer-events: none; /* Zabrání interakci s overlay */
}

.newsletter-container.loading::before {
    filter: blur(5px); /* Rozmazání pozadí při načítání */
    opacity: 1; /* Zajištění, že overlay je viditelný */
}

.newsletter-container.loaded::before {
    filter: none; /* Odstranění rozmazání po načtení obrázku */
    opacity: 1; /* Overlay zůstává viditelný */
}

.newsletter-container_text {
    padding-left: 2rem;
}

.newsletter-container * {
    position: relative;
    z-index: 2; /* zajistí, že text bude nad tmavou vrstvou */
}

.newsletter-container_form form {
    padding-right: 2rem;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.newsletter-container_form form input[type="email"] {
    background-color: transparent;
    outline: none;
    border: 2px var(--primary) solid;
    background-color: white;
    padding: 8px 12px;
    font-size: 1rem;
    width: 20rem;
    height: 2rem;
}

.newsletter-container_form form input[type="submit"] {
    outline: none;
    border: 2px var(--primary) solid;
    background-color: var(--primary);
    color: white;
    padding: 8px 12px;
    font-size: 1rem;
}

@media (max-width: 1500px) {
    .newsletter-container {
        height: 400px;
    }
}

@media (max-width: 1200px) {
    .newsletter-container {
        padding: 100px 0;
        flex-direction: column;
        text-align: center;
        gap: 50px;
}
    .newsletter-container * {
        max-width: 80%;
        margin: auto;
    }
    .newsletter-container_form form {
        padding-right: unset;
    }
}

@media (max-width: 768px) {
    .newsletter-container {
        left: -5vw;
        width: 100vw;
        margin: unset;
    }
}

@media (max-width: 420px) {
    .newsletter-container_form form {
        flex-direction: column;
    }
    .newsletter-container_text {
        padding: 0;
    }
    .newsletter-container {
        padding: 25px 0;
    }
}