.feature-highlight {
    text-align: center;
  }
  
  .feature-highlight h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .feature-highlight p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 40px;
  }
  
  .features-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 40px;
    flex-wrap: wrap;
  }
  
  .features-container .feature {
    flex: 1;
    margin: 0 10px;
    border-radius: 8px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
  }

    .features-container .feature .icon {
        font-size: 40px;
    }
  
  .feature h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 1.5rem;
  }
  
  .feature p {
    color: #777;
  }
  
  .buttons-container {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .btn-primary {
    background-color: var(--accent);
    color: #fff;
  }
  
  .btn-secondary {
    background-color: #fff;
    color: var(--accent);
    border: 2px solid var(--accent);
  }