h2 {
    font-size: 3rem;
}

h5 {
    font-size: 1.5rem;
    margin: 0;
    font-weight: normal;
}

.centered-style {
    text-align: center;
    margin: auto;
}

.center-p p {
    text-align: justify;
    word-spacing: -1px;
}



@media (max-width: 1600px) {
    h5 {
        font-size: 1.5rem;
    }
    h2 {
        font-size: 1.8rem;
    }
}

@media (max-width: 768px) {
    .content-section-div {
        text-align: center;
    }
    .center-p p {
        text-align: center;
        word-spacing: normal;
    }
}