.smaller-landing {
    position: relative;
    background-size: cover;
    background-position: center;
    transition: background-image 0.5s ease;
    overflow: hidden; /* Zajišťuje, že pseudo-element nebude přesahovat */
    min-height: 70dvh;
    opacity: 1;
  }

  .smaller-landing.loaded {
    opacity: 1; /* Plná opacity pro obrázek v plné kvalitě */
  }
  
  .smaller-landing::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: inherit;
    background-position: inherit;
    background-color: rgba(19, 92, 103, 0.03); /* Poloprůhledný barevný overlay */
    z-index: 1;
    transition: opacity 0.5s ease, filter 0.5s ease; /* Přidáno pro hladký přechod filtru */
    pointer-events: none; /* Zajištění, že overlay nebude blokovat interakce */
  }
  
  .smaller-landing.loading::before {
    filter: blur(5px); /* Rozmazání pozadí při načítání */
    opacity: 1;
  }
  
  .smaller-landing.loaded::before {
    filter: none; /* Odstraní rozmazání po načtení obrázku */
    opacity: 1; /* Můžeš upravit, pokud chceš, aby overlay zůstal viditelný */
  }
  
  .homepage-landing-content {
    position: relative;
    z-index: 2; /* Zajistí, že text je nad rozmazaným pozadím */
    color: #fff; /* Zajistí čitelnost textu na rozmazaném pozadí */
  }

  .smaller-landing.about-us {
    background-position: center 0; /* Posune pozadí o 20% nahoru */
  }

  .smaller-landing.about-us::before {
    background-color: rgba(0, 0, 0, 0.07);
  }

  @media (max-width: 768px) {
    .smaller-landing.about-us {
      background-position: center; /* Posune pozadí o 20% nahoru */
    }
  }