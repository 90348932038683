.testimonial-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.testimonial-content {
  width: 80%;
  text-align: center;
}

.testimonial-text {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.testimonial-rating .fa-star {
  color: gold; /* Zlaté hvězdičky pro aktivní hodnocení */
}

.testimonial-rating .text-secondary {
  color: #ccc; /* Šedá pro neaktivní hvězdičky */
}

.testimonial-author {
  font-style: italic;
  color: #666;
}

.testimonial-nav {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 24px;
}

.prev, .next {
  color: #333;
}

.prev:hover, .next:hover {
  color: #000;
}

.testimonial-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; /* Důležité pro umístění tlačítek */
}

.rating {
  margin-top: 50px;
}

.author-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
  align-items: center;
}

.author-info_logo img {
  max-width: 50px;
}

.navigation {
  position: absolute;
  top: 50%;
  width: 95%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

.navigation button {
  background-color: #fff; /* Bílé pozadí */
  border: 1px solid #ccc; /* Lehký rámeček */
  border-radius: 50%; /* Kulatý tvar */
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Jemný stín */
}

.navigation button:hover {
  background-color: #f0f0f0; /* Změna pozadí při hoveru */
}

.testimonial-container .content {
  padding: 20px;
  width: 80%; /* Omezení šířky obsahu */
  text-align: center;
}

/* Ostatní styly zůstávají beze změn */