.process-steps-container {
  position: relative;
  padding: 50px;
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.progress-bar-wrapper {
  background-color: rgba(0, 0, 0, 0.15);
  width: 3px;
  position: absolute;
  left: 31%;
  top: 5%;
  bottom: 0;
  margin: auto;
  height: 65%;
  z-index: 1;
}

.progress-bar {
  background-color: var(--primary);
  width: 100%;
  height: 0;
  will-change: height;
}

.steps {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.element {
  display: flex;
  align-items: center;
  margin-bottom: 100px;
  z-index: 100;
  padding: 20px 0;
  background-color: white;
  position: relative;
}

.icon {
  margin-right: 20px;
  z-index: 2;
}

.element-header {
  z-index: 2;
}

@media (max-width: 1250px) {
  .process-steps-container {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .process-steps-container {
    justify-content: center;
    padding: 0;
  }
}