.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal box */
  .modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    position: relative;
  }
  
  /* Close button */
  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  /* Form styling */
  .modal-form .form-group {
    margin-bottom: 15px;
  }
  
  .modal-form .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .modal-form .form-group input[type="text"],
  .modal-form .form-group input[type="email"],
  .modal-form .form-group input[type="tel"] {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .modal-form .form-group-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .modal-form .form-group-checkbox label {
    margin-left: 5px;
  }
  
  .modal-form button[type="submit"] {
    padding: 10px 20px;
    background-color: var(--primary);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modal-open {
    overflow: hidden;
  }
  
  .modal-form button[type="submit"]:hover {
    background-color: var(--accent);
  }