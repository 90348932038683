.image-text-section {
    display: flex;
    align-items: center;
    margin: 50px 0;
    justify-content: space-between;
    gap: 100px;
  }
  
  .image-text-section.image-left {
    flex-direction: row-reverse;
  }
  
  .image-text-section.image-right {
    flex-direction: row;
  }

  .image-container {
    height: 80dvh;
  }
  
  .image-container img {
    max-width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }
  
  .text-container {
    max-width: 50%;
    padding: 20px;
  }
  
  .text-container h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .text-container h3 {
    font-size: 1.5rem;
    color: #666;
    margin-bottom: 20px;
  }
  
  .features {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  
  .feature {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .feature .icon {
    margin-right: 10px;
    font-size: 1.5rem;
    color: var(--accent);
    min-width: 60px;
    max-width: 60px;
  }
  
  .feature .text h3 {
    margin-bottom: 5px;
  }
  
  .feature .text p {
    color: #555;
  }
  
  .buttons {
    display: flex;
    gap: 20px;
  }
  
  .text-container .buttons {
    margin-top: 50px;
  }

  .btn {
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .btn-primary {
    background-color: var(--accent);
    color: #fff;
    border: none;
    text-align: center;
  }
  
  .btn-secondary {
    background-color: #fff;
    color: var(--accent);
    border: 2px solid var(--accent);
  }

  @media (max-width: 1550px) {
    .text-container h2 {
      font-size: 2.0rem;
      margin-bottom: 20px;
    }
    .text-container h3 {
      font-size: 1.3rem;
      color: #666;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 1000px) {
    .image-text-section.image-left, .image-text-section.image-text-section.image-right {
      flex-direction: column-reverse;
    }
    .image-container {
      height: 350px;
      width: 100%;
    }
    .image-container img {
      width: 100%;
      height: 350px;
    }
    .text-container {
      max-width: 100%;
    }
    .text-container h2 {
      font-size: 1.5rem;
    }
    .text-container h3 {
      font-size: 1rem;
    }
  }

  @media (max-width: 768px) {
    .text-container h3, .text-container h2, .text-container p {
      text-align: center;
    }
    .text-container .features p, .text-container .features h3 {
      text-align: left;
    }
  }

  @media (max-width: 420px) {
    .text-container .buttons {
      flex-wrap: wrap;
      justify-content: center;
    }
    .btn-primary, .btn-secondary {
      width: 100%;
    }
  }