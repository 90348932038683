.services-container {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    flex-wrap: wrap;
  }
  
  .service h3 {
    text-align: left;
  }

  .service {
    flex: 1;
    min-width: 200px;
  }

  .service p {
    text-align: left;
    word-spacing: 5px;
  }
  
  .service img {
    width: 5rem;
    height: 5rem;
  }

  .services-container .service:nth-child(3) img {
    width: 6rem;
    height: 6rem;
  }

  @media (max-width: 768px) {
    .service {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .service p {
      word-spacing: normal;
      text-align: center;
    }
  }