.partners-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.partners-container h3 {
    max-width: 20vw;
    font-size: 1.5rem;
}

.partners-logos-container img {
    max-height: 3rem;
}

.partners-logos-container {
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 1600px) {
    .partners-container {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }
    .partners-logos-container {
        justify-content: center;
    }
    .partners-logos-container img {
        max-height: 2.5rem;
    }
    .partners-container h3 {
        max-width: 60%;
    }
}